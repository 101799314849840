import EService from '~/parts/core/services/eService';
import { type ReferentialInfo, type Referential } from '~/parts/referentials';
import { InputType } from '~/parts/core';
import PermissionsConstantsProvider from "~/parts/users/permissions/permissionsConstantsProvider";

const PermissionsProvider = {
    SuperAdministrator: "SuperAdministrator",
    DatabaseAdministrator: "DatabaseAdministrator",
    UserAdministrator: "UserAdministrator",
    Video: "Video",
    MediasAdministrator: "MediasAdministrator",
    Monitoring: "Monitoring",
    OrganisationAdministrator: "OrganisationAdministrator",
    Organisation: "Organisation"
}

class PermissionsService extends EService {

    constructor(useApiKey: boolean = false) {
        super(useApiKey);
    }

    static getReferentialsInfos(): Array<ReferentialInfo> {
        return [
            {
                typeName: PermissionsConstantsProvider.AccessTypeName,
                metaData: [
                    { text: "#", value: "id", inputOption: { type: InputType.Number }, isKey: true },
                    { textKey: "accessname", value: "name", inputOption: { type: InputType.Text } },
                    { textKey: "accessCode", value: "code", inputOption: { type: InputType.Text } },
                    {
                        textKey: "permissions", value: "permissions", inputOption: {
                            type: InputType.Dynamic, selectMode: "multiple",
                            lookup: {
                                typeName: PermissionsConstantsProvider.PermissionTypeName,
                                valueField: "id",
                                textField: "name"
                            }
                        }
                    }
                ],
                helpers: {
                    getSearchablePropertiesValues: (item: Access, lookups: { [key: string]: Array<Referential> }) => {
                        const searchablePropertiesValues = [item.id, item.code, item.name];
                        const lookupData = lookups.permissions as Array<Permission>;
                        const permissions = lookupData.filter(p => item.permissions.includes(p.id as number));
                        if (!!permissions)
                            searchablePropertiesValues.push(...permissions.map(p => p.name));
                        return searchablePropertiesValues;
                    }
                }
            },
            {
                typeName: PermissionsConstantsProvider.PermissionTypeName,
                loadOnInit: false,
                metaData: [
                    { text: "#", value: "id", inputOption: { type: InputType.Number }, isKey: true },
                    { textKey: "name", value: "name", inputOption: { type: InputType.Text } },
                    { textKey: "code", value: "code", inputOption: { type: InputType.Text }, readOnly: true },
                    {
                        textKey: "parent", value: "parentId", inputOption: {
                            type: InputType.Dynamic,
                            lookup: {
                                typeName: PermissionsConstantsProvider.PermissionTypeName,
                                valueField: "id",
                                textField: "name"
                            }
                        }
                    },
                    { textKey: "level", value: "level", inputOption: { type: InputType.Text }, readOnly: true }
                ],
                helpers: {
                    getSearchablePropertiesValues: (item: Permission, lookups: { [key: string]: Array<Referential> }) => {
                        const searchablePropertiesValues = [item.id, item.code, item.name, item.level];
                        const lookupData = lookups.permissions as Array<Permission>;
                        const permissionParent = lookupData.find(p => p.id == item.parentId);
                        if (!!permissionParent)
                            searchablePropertiesValues.push(permissionParent.name);
                        return searchablePropertiesValues;
                    }
                }
            }
        ];
    }

    async initAccessAndPermissions() {
        const response = await this.http.get('access/init/');
        return response;
    }

    async getAllPermissions() {
        const permissions = await this.http.get<Array<Permission>>('access/AllPermissions/');
        return permissions;
    }

    async savePermission(permission: Permission) {
        const response = await this.http.post<Permission, Permission>('access/permission/save/', permission);
        return response;
    }

    async deletePermission(permission: Permission) {
        const response = await this.http.post<any, Permission>(`access/permission/delete/`, permission);
        return response;
    }

    async getAllAccess() {
        const accesses = await this.http.get<Array<Access>>('access/AllAccess/');
        return accesses;
    }

    async saveAccess(access: Access) {
        const response = await this.http.post<Access, Access>('access/save/', access);
        return response;
    }

    async deleteAccess(access: Access) {
        const response = await this.http.post<any, Access>(`access/delete/`, access);
        return response;
    }
}

export default PermissionsService;

export { PermissionsProvider }; 
